<template>
    <div @click.prevent="getData()">
        <kk-sms-dialog
            ref="smsDialog"
            :contacts="smsData.contacts"
            :teams="smsData.teams"
            :employees="smsData.employees"
            :positions="smsData.positions"
            :sender-names="senders"
            :selected-sendername="selectedSendername"
            :error-object="smsError"
            :redirect-to-on-click="redirectLink"
            @send="send"
            @select-sendername="didSelectSendername"
        />
    </div>
</template>

<script>
import { kkSmsDialog, ks, PhoneNumber, PhoneNumberType } from 'vue-components';
import { mapState } from 'vuex';

export default {
    name: 'send-sms',
    components: {
        kkSmsDialog,
    },
    data() {
        return {
            senders: [],
            smsData: {
                contacts: [],
                employees: [],
                teams: [],
                positions: [],
            },
            selectedSendername: null,
            smsError: {
                failed_receivers: [],
                message: '',
            },
            dataFetched: false,
        };
    },
    computed: {
        ...mapState({
            sms: state => state.sms.sms,
            smsSettings: state => state.sms.settings,
        }),

        redirectLink() {
            if (this.smsSettings.displayButton && this.smsSettings.moduleActive) {
                return '';
            }

            return '/bedrift/innstillinger/sms';
        },
    },
    watch: {
        sms(data) {
            // If data is empty, dont do anything
            if (data && Object.keys(data).length === 0 && data.constructor === Object) {
                return;
            }

            /**
            * data parameter has references to the vuex state. This will throw error if we manipulate
            * the object further down the chain. Therefore we have to remove referances by using stringify.
            */
            if (Object.keys(data).length) { // to make sure empty object won't open sms modal
                this.openSmsFromEvent(JSON.parse(JSON.stringify(data)));
            }
        },
    },
    mounted() {
        this.addListenerForSendSms();
    },
    methods: {
        send(e) {
            const url = '/api/sms/send';
            ks.post(url, e, true).then((response) => {
                this.smsError = response.data.sending_result;

                if (this.smsError.failed_receivers.length === 0) {
                    ks.toast('Meldingen ble sendt');
                }
            }, (error) => {
                ks.alert('Feil', error);
            });
        },
        addListenerForSendSms() {
            let that = this;
            window.addEventListener('openSmsDialog', function(e) {
                that.openSmsFromEvent(e);
            });
        },
        openSmsFromEvent(payload) {
            let data = {};

            // If parameter is an event, get the custom atributes from the target (clicked button to trigger sms)
            // Else, the data in payload comes from vuex
            if (payload instanceof Event) {
                data = {
                    data: payload.detail.smsdata,
                    type: payload.detail.datatype,
                    message: payload.detail.message,
                    link: payload.detail.link,
                    receivers: payload.detail.receivers,
                };
            } else {
                data = payload;
            }

            this.getData().then(() => {
                this.$refs['smsDialog'].modalOpen = true;

                if (data.type === undefined || data.type === null) {
                    if (data.message !== undefined && data.message !== null) {
                        this.$refs['smsDialog'].smsObj.message = data.message;
                    }

                    if (data.link !== undefined && data.link !== null) {
                        this.$refs['smsDialog'].smsObj.link = data.link;
                    }

                    if (data.receivers !== undefined && data.receivers !== null) {
                        this.$refs['smsDialog'].smsObj.receivers = data.receivers;
                    }
                }

                if (data.type === 'hours') {
                    let sendersUrl = '/api/sms/timereginfo/' + data.data;
                    ks.get(sendersUrl, null, false).then((response) => {
                        let user = response.data.timereg.user;
                        this.$refs['smsDialog'].addContact({ type: 'employeeId', value: user });
                        let base = location.origin;

                        if (data.message !== undefined && data.message !== null) {
                            this.$refs['smsDialog'].smsObj.message = data.message;
                        }
                        this.$refs['smsDialog'].smsObj.link = base + '/mobile/hours/' + data.data;
                    }, (err) => {
                        ks.alert('Feil', err);
                    });
                }

                if (data.type === 'files') {
                    let infoUrl = '/api/sms/userinfo/';
                    ks.get(infoUrl, null, false).then((response) => {
                        let user = response.data.userinfo;
                        let base = location.origin;

                        if (data.message !== undefined && data.message !== null) {
                            this.$refs['smsDialog'].smsObj.message = data.message;
                        } else {
                            this.$refs['smsDialog'].smsObj.message = 'Hei\n\nDet finnes nå en ny/endret fil.\nSe link\n\nMvh. ' + user.firstName + ' ' + user.lastName + '\n' + user.companyName;
                        }
                        this.$refs['smsDialog'].smsObj.link = base + '/fil/' + data.data;
                    }, (err) => {
                        ks.alert('Feil', err);
                    });
                }
                // Build this block with other types
            });
        },
        didSelectSendername(e) {
            if (e === null || e === -1) {
                return;
            }
            const url = '/api/sms/userdefault/' + e;
            ks.post(url, e, true);
        },

        getData() {
            return new Promise((resolve, reject) => {
                if (this.dataFetched) {
                    resolve();

                    return;
                }
                // Getting sendernames
                let sendersUrl = '/api/senders';
                ks.get(sendersUrl, null, false).then((response) => {
                    this.senders = response.data.data.senders;

                    for (let sender of this.senders) {
                        if (sender.description !== '' && sender.description !== null && sender.description !== undefined) {
                            sender.sender_name = sender.sender_name + ' (' + sender.description + ')';
                        }
                    }
                    this.selectedSendername = response.data.data.defaultSender ? response.data.data.defaultSender.id : null;
                    // Getting contacts
                    let contactsUrl = '/api/addressbook';
                    ks.get(contactsUrl, { all: 1 }, false).then((response) => {
                        const res = response.data.data;
                        let obj = {
                            contacts: res.contacts,
                            teams: res.teams,
                            positions: res.positions,
                            employees: res.employees,
                        };

                        for (var person of obj.employees) {
                            this.transferNumbers(person);
                        }

                        for (var contact of obj.contacts) {
                            for (var contactPerson of contact.persons) {
                                contactPerson.name = contactPerson.full_name;
                                this.transferNumbers(contactPerson);
                            }
                            this.transferNumbers(contact);
                        }

                        // Adding an all-employees object
                        const validPhoneNumberThatNobodyCanUse = '+A000';
                        let allEmployees = {
                            id: 0,
                            mobile: validPhoneNumberThatNobodyCanUse,
                            team: Lang.get('sms-dialog.Alle ansatte'),
                            name: '-- ' + Lang.get('sms-dialog.Alle ansatte') + '--',
                            position: Lang.get('sms-dialog.Alle ansatte'),
                        };
                        obj.employees.push(allEmployees);

                        this.smsData = obj;
                        this.dataFetched = true;
                        resolve();
                    }, (err) => {
                        ks.alert('Feil', JSON.stringify(err));
                        reject();
                    });
                }, (err) => {
                    ks.alert('Feil', JSON.stringify(err));
                    reject();
                });
            });
        },

        transferNumbers(person) {
            const mobile = new PhoneNumber(person.mobile, 47);

            if (mobile.validate() && mobile.getType() === PhoneNumberType.mobile) {
                return;
            } else {
                person.mobile = '';
            }

            const phone = new PhoneNumber(person.phone, 47);

            if (phone.validate() && phone.getType() === PhoneNumberType.mobile) {
                person.mobile = person.phone;
            }
        },
    },
};
</script>
