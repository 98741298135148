<template>
    <ul class="main">
        <slot />
        <li>
            <kk-toggle v-model="menu.sticky">
                {{ trans("shared.Åpen meny") }}
            </kk-toggle>
        </li>
        <li v-if="isMainNavFeaturesToggleAvailable">
            <kk-toggle :value="featuresToggle" @input="onFeaturesToggleClicked">
                {{
                    trans("shared.Aktiver beta")
                }}
            </kk-toggle>
        </li>
        <li v-if="isMainNavFeaturesToggleAvailable">
            <v-select
                class="language-select"
                :value="user.user.language"
                :options="languages"
                :reduce="l => l.value"
                :clearable="false"
                @input="updateUserLanguage($event)"
            />
        </li>
    </ul>
</template>

<script>
// Mixin
import featureAvailable from './../../../mixins/featureAvailable';

import { kkToggle } from 'vue-components';
import { Features, FeaturesToggleLocalStorageKey } from '../../../config/features';
import { helpers } from '@/mixins/helpers';
import { mapState, mapActions } from 'vuex';
import vSelect from 'vue-select';
import profileStore from '../../profile/stores/profile-store';

export default {
    name: 'main-nav',
    components: {
        kkToggle,
        vSelect,
    },
    mixins: [
        featureAvailable,
        helpers,
    ],
    props: {
        menu: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    data: () => ({
        featuresToggle: false,
    }),
    computed: {
        ...mapState({
            user: state => state.user,
        }),
        isMainNavFeaturesToggleAvailable() {
            return this.isFeatureAvailable(Features.MAIN_NAV_FEATURES_TOGGLE);
        },
    },
    mounted() {
        this.featuresToggle = localStorage.getItem(FeaturesToggleLocalStorageKey) === 'true';
    },
    beforeCreate() {
        if (!this.$store.hasModule('profileStore')) {
            this.$store.registerModule('profileStore', profileStore);
        }
    },
    methods: {
        onFeaturesToggleClicked(value) {
            localStorage.setItem(FeaturesToggleLocalStorageKey, value);
            location.reload();
        },
        ...mapActions({
            updateLoggedUser: 'profileStore/updateLoggedUser',
            clearSession: 'profileStore/clearSession',
        }),
        async updateUserLanguage(newValue) {
            const dataToSend = {
                user: {
                    language: newValue,
                },
            };

            await this.updateLoggedUser(dataToSend);
            await this.clearSession();

            window.location.reload();
        },
    },
};
</script>

<style lang="scss" scoped>
.main {
    padding: 0;
    margin: 0;
    list-style: none;
    height: 100%;
    background-color: #FFF;
    position: relative;
    z-index: 998;
    box-shadow: 0 3px 10px 0 rgba(0,0,0,0.15);
    width: auto;
    max-width: 120px;
    min-width: 1em; /* IE11 fix, max-width alone did not work */
    font-size: 1vh;
    // Stop responsive scaling of side-menu icons & text after 915px
    @media (min-height: 915px) {
        font-size: 10px;
    }

    .item {
        box-sizing: border-box;
        position: relative;
        height: 10em;
        max-height: 110px;
        max-width: 100%;
        width: 15em;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        align-content: center;
        overflow: hidden;
        border-style: solid;
        border-width: 0 0 1px 0;
        border-color: rgba(214, 213, 213, 0.5);
        text-align: center;
        cursor: pointer;
        color: #332E38;

        &:hover {
            color: #0AAEF3;
        }

        &.active {
            color: #0AAEF3;
            &::after {
                content: '';
                position: absolute;
                height: 32px;
                width: 32px;
                background-color: #0AAEF3;
                transform: rotate(135deg);
                right: -16px;
                bottom: -16px;
            }
        }

        &.admin {
            height: 60px;
            background-color: #efefef;
            .icon {
                flex-basis: 100%;
                font-size: 25px;
                margin: 0;
            }
            .name {
                width: auto;
            }
        }

        .icon {
            font-size: 2.6em;
            margin-bottom: 0.3em;
        }

        .name {
            margin: 0;
            font-size: 1.3em;
            letter-spacing: 0.18px;
            line-height: 16px;
            padding-top: 4px;
            width: 100%;
        }

        .item-link,
        .item-link:active {
            color: inherit;
            outline: none;
            text-decoration: none;
        }
    }

    ::v-deep .kk-toggle {
        margin: 5px;
        line-height: 1;
    }

    .language-select {
        margin: 5px;
    }
}
</style>
