<template>
    <section>
        <button class="header-icon-button" @click="toggleModal">
            <fa-icon :icon="['fal', 'comment-smile']" />
            <span>{{ trans('feedback.Tilbakemelding') }}</span>
        </button>

        <kk-modal
            v-if="showFeedbackModal"
            @cancel="toggleModal"
            @click-outside="toggleModal"
        >
            <template #title>
                <h2>{{ trans("feedback.Tilbakemeldingsskjema") }}</h2>
            </template>

            <template #content>
                <form class="feedback-form">
                    <section class="info">
                        <p>
                            {{ trans('feedback.Tilbakemeldingen din er avgjørende for å hjelpe oss å gi deg den beste mulige opplevelsen. I tillegg, hvis det er noe du misliker eller noe du er spesielt glad for, vil vi gjerne høre om det!') }}
                        </p>
                    </section>
                    <section class="radios">
                        <kk-checkbox
                            v-for="option in typeOptions"
                            :key="option.value"
                            :value="isTypeSelected(option.value)"
                            shape="circle"
                            @input="setFormType(option.value)"
                        >
                            {{ option.text }}
                        </kk-checkbox>
                    </section>

                    <section :class="['text-area', { error: showError }]">
                        <label>
                            {{ trans('feedback.Tilbakemelding') }}
                            <kk-textarea v-model="form.text" />
                        </label>
                        <p v-show="showError">
                            {{ trans('feedback.Vennligst fyll inn tilbakemelding') }}
                        </p>
                    </section>
                </form>
            </template>

            <template #footer>
                <kk-button
                    type="basic"
                    class="action-button"
                    :loading="isLoading"
                    :disabled="isLoading"
                    @click="toggleModal"
                >
                    {{ trans('shared.Avbryt') }}
                </kk-button>

                <kk-button
                    type="primary"
                    :loading="isLoading"
                    :disabled="isLoading"
                    @click="doSendFeedback()"
                >
                    {{ trans('feedback.Send tilbakemelding') }}
                </kk-button>
            </template>
        </kk-modal>
    </section>
</template>

<script>
// Libs
import axios from 'axios';
import { logThrowApiError } from '@/libs/kk-api';
// Components
import { ks, kkModal, kkCheckbox, kkTextarea, kkButton } from 'vue-components';

export default {
    name: 'send-feedback',
    components: {
        kkModal,
        kkCheckbox,
        kkTextarea,
        kkButton,
    },
    data() {
        return {
            showFeedbackModal: false,
            form: {
                type: 'neutral',
                text: '',
                url: null,
            },
            formError: false,
            typeOptions: [
                {
                    text: this.trans('feedback.Jeg har et forslag'),
                    value: 'neutral',
                },
                {
                    text: this.trans('feedback.Jeg er ikke fornøyd med noe'),
                    value: 'negative',
                },
                {
                    text: this.trans('feedback.Jeg er veldig fornøyd med'),
                    value: 'positive',
                },
            ],
            isLoading: false,
        };
    },
    computed: {
        showError() {
            return this.formError && !this.form.text.length;
        },
    },
    methods: {
        isFormValidated() {
            if (!this.form.text.length > 0) {
                this.formError = true;

                return false;
            }

            this.formError = false;

            return true;
        },
        async doSendFeedback() {
            const url = '/api/feedback/publish';
            const body = {
                ...this.form,
                url: this.getCurrentURL(),
            };
            const isValidated = this.isFormValidated();

            if (!isValidated) {
                return;
            }

            this.isLoading = true;

            try {
                await axios.patch(url, body);

                ks.toast(this.trans(

                    this.trans('feedback.Tusen takk. Vi setter pris på dine innspill og tar alle tilbakemeldinger på alvor.'),
                ), 4000);
                this.toggleModal();
            } catch (error) {
                logThrowApiError(error, url, body, true);
            } finally {
                this.isLoading = false;
            }
        },
        setFormType(value) {
            this.form.type = value;
        },
        getCurrentURL() {
            return window.location.href;
        },
        isTypeSelected(value) {
            return this.form.type === value;
        },
        toggleModal() {
            this.resetForm();
            this.showFeedbackModal = !this.showFeedbackModal;
        },
        resetForm() {
            this.form = {
                type: 'neutral',
                text: '',
                url: null,
            };
            this.formError = false;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~sass/_variables.scss';
.feedback-form {
    display: grid;
    grid-template-columns: 1fr;
    gap: 30px;
    font-size: 14px;
    max-width: 800px;

    section {
        &.info {
            font-size: 16px;
            line-height: 22px;
        }
        &.radios {
            display: flex;
            gap: 10px;
            justify-content: space-between;
        }
        &.text-area {
            label {
                width: 100%;
            }
            p {
                margin-top: 5px;
            }
        }
    }

    .kk-textarea::v-deep {
        margin-top: 5px;

        textarea {
            height: 240px;
            resize: none;
            outline: none;
        }
    }

    .error {
        color: $danger;

        .kk-textarea::v-deep {
            border: 2px solid $danger;
            border-radius: 4px;
        }
    }
}

.kk-modal::v-deep {
    .dialogbox .footer {
        justify-content: space-between !important;
        border-top: 1px solid #D8D8D8;
        margin: 20px -20px 0;
        padding: 20px;
        border-radius: 0 0 7px 7px;
    }
}

</style>
